export const Environment = {
    backendUrl: isProduction() ? window.webappConfig.REACT_APP_BACKEND_URL : process.env.REACT_APP_BACKEND_URL,
    intercomAppId: isProduction() ? window.webappConfig.REACT_APP_INTERCOM_APP_ID : process.env.REACT_APP_INTERCOM_APP_ID,
    sentryEnabled: isProduction(),
    isDev: isEnvironment('development'),
    isProd: isEnvironment('production'),
    isBackendProd: isBackendEnvironment('PROD'),
    stripePublishableKey: isProduction() ? window.webappConfig.REACT_APP_STRIPE_PUBLISHABLE_KEY : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    allowDirectStripeIntegration: isProduction() ? window.webappConfig.REACT_APP_ALLOW_DIRECT_STRIPE_INTEGRATION : process.env.REACT_APP_ALLOW_DIRECT_STRIPE_INTEGRATION
}

function isEnvironment (env) {
    return process.env.NODE_ENV === env
}

function isProduction () {
    return isEnvironment('production')
}

function isBackendEnvironment (env) {
    return isProduction() ? window.webappConfig.REACT_APP_BACKEND_ENVIRONMENT === env : process.env.REACT_APP_BACKEND_ENVIRONMENT === env
}
