import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import { prepareForPayment } from '../../services/accounts.service'
import { loadStripe } from '@stripe/stripe-js'
import { Environment } from '../../environment'
import {
    AddressElement,
    Elements,
    PaymentElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js'
import lang from '../../utils/lang'
import FormButton from '../../components/GUI/FormButton'
import { SteppedProgress } from '../../components/GUI/SteppedProgress'
import { firmCommitmentConfirmation, getPricingPlanByName } from './PricingPlans'
import { PlanDetails } from '../../components/GUI/PlanDetails'

const stripePromise = loadStripe(Environment.stripePublishableKey, {locale:"en"})

function SetupForm(paymentDetails) {
    const stripe = useStripe()
    const elements = useElements()

    const [errorMessage, setErrorMessage] = useState(null)

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return null
        }

        const confirmationOptions = {
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/redirect-to?target=/account/upgrade-summary&messageKey=your_subscription_will_be_active_in_a_few_seconds`,
            },
            redirect: 'always'
        }
        const {error} = await stripe.confirmSetup(confirmationOptions)

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    const addressOptions = {
        mode: 'billing',
        defaultValues: {}
    }
    if (paymentDetails.address) {
        addressOptions.defaultValues['address'] = {
            line1: paymentDetails.address.street,
            line2: '',
            city: paymentDetails.address.city,
            state: paymentDetails.address.state,
            postal_code: paymentDetails.address.zipcode,
            country: paymentDetails.address.countryCode
        }
    }

    const isYearlySubscription = paymentDetails.takeImmediatePayment
    const pricingPlan = getPricingPlanByName(paymentDetails.subscriptionPlanName)
    const paymentMessage = isYearlySubscription ? lang.d('prepaidPlanDetails', { amount: paymentDetails.amount }) : lang.d('monthlyBilledPlanDetails')
    return (
        <>
            <PlanDetails title={lang.d('selectedPlan', { plan: lang.d(pricingPlan.id)})} description={paymentMessage} />
            <div className="paymentDetails">
                <div className="title">{lang.d('paymentDetails')}</div>
                <div>{lang.d('paymentDetailsDescription')}</div>
            </div>
            <form onSubmit={handleSubmit} className="paymentForm">
                <PaymentElement options={{ layout: "accordion" }} />
                <div className="legal-notice">{lang.d('i-legally-commit')}</div>
                <hr className="divider" />
                <div className="actions">
                    <FormButton className="action" disabled={!stripe} onClick={handleSubmit.bind(this)}>{lang.d('placeOrder')}</FormButton>
                </div>
                <div className="firm-commitment-notice">
                    <span>{firmCommitmentConfirmation(pricingPlan.id)}</span>
                </div>
            </form>
        </>
    )
}

class PaymentDetails extends React.Component {

    constructor (props) {
        super(props)
        this.steps = [
            {  name: lang.d('upgrade-account-description'), status: 'complete' },
            {  name: lang.d('completePaymentDetails'), status: 'current' },
        ]
        this.state = {
            paymentDetails: null,
            stripeOptions: null
        }
    }

    async componentDidMount () {
        this.props.setHeader({})
        const paymentDetails = await prepareForPayment()
        this.setState({ paymentDetails, stripeOptions: {
            clientSecret: paymentDetails.sessionKey
        }})
    }

    renderPaymentDetails () {
        const appearance = {
            theme: 'stripe',
            borderRadius: '4px',
            fontSize: '12px',
            fontFamily: 'Helvetica Neue, Helvetica, Roboto, sans-serif'
        }

        return <Elements stripe={stripePromise} options={this.state.stripeOptions} appearance={appearance}>
            <SetupForm {...this.state.paymentDetails}/>
        </Elements>
    }

    render () {
        if (Environment.allowDirectStripeIntegration) {
            return (
                <div className="PaymentDetails">
                    <div className="sectioned-page">
                        <div className="sectioned-page-container with-expanded-header">
                            <div className="section">
                                <div className="display-title">Upgrade your account</div>
                                <SteppedProgress steps={this.steps} />
                                {this.state.stripeOptions && this.renderPaymentDetails()}
                            </div>
                        </div>
                    </div>
                </div>)
        } else {
            return <div className="AddPaymentMethod sectioned-page">
                <div className="sectioned-page-container with-expanded-header">
                    <div className="section">
                        <div className="page-title">{lang.d('add_a_payment_method')}</div>
                        <div className="page-description">
                            {lang.d('unable_to_add_payment_method')}
                        </div>
                    </div>
                </div>
            </div>
        }
    }
}

PaymentDetails.propTypes = {
    setHeader: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(PaymentDetails)
