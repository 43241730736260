import React, { createRef } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { PrivateRoute } from '../../components/CustomRoutes'
import { eventsService } from '../../services/events.service'
import { seasonService } from '../../services/season.service'
import PageSwitcher from '../../components/GUI/PageSwitcher'
import Summary from './Summary'
import ManageStatuses from './ManageStatuses'
import ManageForSale from './ManageForSale'
import ManageTables from './ManageTables'
import ManageChannels from './ManageChannels'
import ManageCategories from './ManageCategories'
import lang from '../../utils/lang'
import StatusChanges from './StatusChanges'
import { history } from '../../store'
import { exposeErrors, hasErrorCode, showFailedToContactServerError } from '../../utils/errors'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { notificationService } from '../../services/notification.service'
import { updateEventKey, playNewestAddedEventKey, updateEventName } from '../../actions/events'
import { Switch } from 'react-router'
import { closeDialog, openModalDialog } from '../../actions/modalDialog'
import OccupancyProgressBar from '../../components/Events/OccupancyProgressBar'
import { SelectEventsForm } from '../../components/Forms/SelectEventsForm'
import { cloneObject } from '../../utils/helperFunctions'
import { contextualMenuService } from '../../services/contextualMenu.service'

const REFRESH_EVENT_SUMMARY_SEC = 10

const editEventNameMenuOptions = [
    {
        type: 'edit',
        caption: lang.d('edit_name'),
        uiEvent: 'editName'
    },
    {
        type: 'delete',
        caption: lang.d('remove_name'),
        uiEvent: 'removeName'
    },
]

class EventDetail extends React.PureComponent {
    state = {
        event: null,
        eventReport: null,
        editingEventKey: false,
        subpageHeight: null,
        creatingSeasonalEvent: false,
        creatingPartialSeason: false,
        managingPartialSeasonEvents: false,
        editNameContextualMenuOpened: false
    }

    constructor (props) {
        super(props)
        this.subpageRef = createRef()
        this.setSubpageHeight = this.setSubpageHeight.bind(this)
        this.refreshEventSummaryTimeout = null
    }

    async retrieveData () {
        this.refreshEventSummary()
        await eventsService.retrieveEvent(this.props.match.params.eventKey)
            .then(event => this.setState({ event }))
            .catch(err => {
                if (hasErrorCode(err, 'EVENT_NOT_FOUND')) {
                    history.replace(`/charts/${this.props.match.params.chartKey}/events/`)
                    notificationService.error(lang.d('error'), lang.d('event_not_found'))
                } else showFailedToContactServerError()
            })
    }

    async getSeasonData () {
        let seasonData = {}
        await seasonService.retrieveSeason(this.props.match.params.eventKey)
            .then(season => seasonData = season)
            .catch(err => {
                if (hasErrorCode(err, 'CHART_NOT_FOUND')) {
                    history.replace('/')
                    notificationService.error(lang.d('error'), lang.d('chart_not_found'))
                } else {
                    showFailedToContactServerError()
                }
            })
        return seasonData
    }

    refreshEventSummary () {
        clearTimeout(this.refreshEventSummaryTimeout)
        eventsService.reportEventSummaryByAvailabilityReason(this.props.match.params.eventKey)
            .then(report => this.setState({ eventReport: report }))
            .catch(err => {
                if (!hasErrorCode(err, 'EVENT_NOT_FOUND')) showFailedToContactServerError()
            })
            .finally(() => {
                this.refreshEventSummaryTimeout = setTimeout(() => {
                    requestAnimationFrame(() => this.refreshEventSummary())
                }, REFRESH_EVENT_SUMMARY_SEC * 1000)
            })
    }

    async componentDidMount () {
        await this.retrieveData()
        requestAnimationFrame(() => this.setSubpageHeight())
        window.addEventListener('resize', this.setSubpageHeight)
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.setSubpageHeight)
        clearTimeout(this.refreshEventSummaryTimeout)
    }

    requestEventDelete () {
        const typeString = this.isSeason() ? 'season' : 'event'
        const goToEventKey = this.state.event.topLevelSeasonKey
        this.props.openModalDialog({
            title: lang.d(`delete_${typeString}`),
            message: lang.d(`delete_${typeString}_confirmation`),
            successMessage: lang.d(`${typeString}_deleted`),
            doneIcon: 'delete',
            settings: {
                onAccept: () => this.deleteEvent(goToEventKey),
                acceptCaption: lang.d('delete'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async deleteEvent (goToEventKey = undefined) {
        await exposeErrors(eventsService.deleteEvent(this.state.event.key), () => this.props.closeModalDialog())
        this.props.removeEvent(this.state.event.key)
        history.replace(`/charts/${this.props.match.params.chartKey}${goToEventKey ? '/events/' + goToEventKey : ''}`)
    }

    requestMarkAsInThePast () {
        this.props.openModalDialog({
            title: lang.d('mark_as_in_the_past'),
            message: lang.d('mark_event_as_in_the_past_confirmation'),
            successMessage: lang.d('event_is_in_the_past'),
            doneIcon: 'event-done',
            settings: {
                onAccept: () => this.markEventAsInThePast(),
                acceptCaption: lang.d('mark_as_in_the_past'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async markEventAsInThePast () {
        const event = cloneObject(this.state.event)
        event.isInThePast = true
        await exposeErrors(eventsService.markAsInThePast(event.key), () => this.props.closeModalDialog())
        this.setState({ event: event })
        this.props.onEventChanges()
    }

    requestRemoveName () {
        this.props.openModalDialog({
            title: lang.d('remove_name'),
            message: lang.d('remove_name_confirmation'),
            successMessage: lang.d('name_removed'),
            doneIcon: 'check',
            settings: {
                onAccept: () => this.removeName(),
                acceptCaption: lang.d('remove_name'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    removeName () {
        eventsService.updateEventName(this.state.event.key, '')
            .then(() => this.eventNameUpdated())
    }

    eventNameUpdated (name) {
        const event = cloneObject(this.state.event)
        event.name = name
        this.setState({ event: event })
        this.props.onEventChanges()
    }

    async createSeasonEvent () {
        this.props.clearSearch()
        if (!this.state.event.isTopLevelSeason) {
            return
        }
        this.setState({ creatingSeasonalEvent: true })
        const events = await seasonService.createSeasonEvent(this.state.event.key)
        this.setState({ creatingSeasonalEvent: false })
        this.props.playNewestAddedEventKey(events[0].key)
        this.props.onEventChanges()
    }

    async createPartialSeason () {
        this.props.clearSearch()
        if (!this.state.event.isTopLevelSeason) {
            return
        }
        this.setState({ creatingPartialSeason: true })
        const partialSeason = await seasonService.createPartialSeason(this.state.event.key)
        this.setState({ creatingPartialSeason: false })
        this.props.playNewestAddedEventKey(partialSeason.key)
        this.props.onEventChanges()
    }

    renderPlaceholder () {
        return <div className="EventDetail loading-placeholder">
            <div className="fake-item big"/>
            <div className="fake-item"/>
            <div className="fake-item"/>
        </div>
    }

    setSubpageHeight () {
        if (this.subpageRef.current) {
            const absoluteTop = this.subpageRef.current.getBoundingClientRect().top
            this.setState({ subpageHeight: window.innerHeight - absoluteTop - 26 })
        }
    }

    renderOccupancyProgressBar () {
        const { eventReport } = this.state

        if (!eventReport) {
            return <div className="loading-placeholder">
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        }

        return <OccupancyProgressBar
            eventReport={eventReport}
        />
    }

    async managePartialSeasonEvents () {
        if (!this.state.event.isPartialSeason) {
            return
        }
        this.setState({ managingPartialSeasonEvents: true })
        let seasonData = await this.getSeasonData()
        this.setState({ managingPartialSeasonEvents: false })
        const initialValues = {}
        const assignableEvents = this.props.chartEvents.filter(event => event.topLevelSeasonKey === seasonData.topLevelSeasonKey && !event.isPartialSeason)
        const eventInSeasonKeys = seasonData.events.map(eventInSeason => eventInSeason.key)
        assignableEvents.forEach(event => initialValues[event.key] = eventInSeasonKeys.includes(event.key))
        this.props.openModalDialog({
            title: lang.d('manage_events_in_partial_season'),
            successMessage: lang.d('events_updated'),
            onSubmit: async (values) => {
                const addedEventKeys = Object.keys(values).filter(key => values[key] === true && !eventInSeasonKeys.includes(key))
                const removedEventKeys = eventInSeasonKeys.filter(key => values[key] === false)
                const topLevelSeasonKey = seasonData.topLevelSeasonKey
                const partialSeasonKey = seasonData.key
                const addPromise = addedEventKeys.length > 0 ? seasonService.addEventsToPartialSeason(topLevelSeasonKey, partialSeasonKey, addedEventKeys) : Promise.resolve()
                const removePromise = removedEventKeys.length > 0 ? seasonService.removeEventsFromPartialSeason(topLevelSeasonKey, partialSeasonKey, removedEventKeys) : Promise.resolve()
                await exposeErrors(Promise.all([addPromise, removePromise]), () => this.props.closeModalDialog())
            },
            settings: {
                acceptCaption: lang.d('confirm'),
                formik: {
                    initialValues,
                    render: props => <SelectEventsForm {...props} events={assignableEvents} />
                }
            }
        })
    }

    isSeason () {
        return this.state.event.isPartialSeason || this.state.event.isTopLevelSeason
    }

    onEditEventNameAction (uiEvent) {
        this.setState({
            editNameContextualMenuOpened: false
        })
        switch (uiEvent) {
        case 'editName':
            this.props.updateEventName(this.state.event.key, this.state.event.name, this.isSeason(), name => this.eventNameUpdated(name))
            break
        case 'removeName':
            this.requestRemoveName()
            break
        default:
            throw new Error('Unknown uiEvent: ' + uiEvent)
        }
    }

    showUpdateNameOptions (e) {
        if (this.state.event.name) {
            this.setState({ editNameContextualMenuOpened: true })
            contextualMenuService.show(editEventNameMenuOptions, {
                onSelect: uiEvent => this.onEditEventNameAction(uiEvent),
                onClose: () => this.setState({ editNameContextualMenuOpened: false }),
                position: {
                    left: e.clientX,
                    top: e.clientY
                }
            })
        } else {
            this.props.updateEventName(this.state.event.key, this.state.event.name, this.isSeason(), name => this.eventNameUpdated(name))
        }
    }

    render () {
        if (!this.state.event) {
            return this.renderPlaceholder()
        }

        const { event: { key, createdOn, isPartialSeason, isTopLevelSeason, topLevelSeasonKey, isEventInSeason, isInThePast, name }, creatingSeasonalEvent, creatingPartialSeason, managingPartialSeasonEvents } = this.state
        const basePath = `/charts/${this.props.match.params.chartKey}/events/${key}`
        const baseRoute = '/charts/:chartKey/events/:eventKey'
        const canEditKey = !isEventInSeason || !isInThePast
        let topLevelSeasonName = undefined
        if (topLevelSeasonKey) {
            topLevelSeasonName = this.props.chartEvents.filter(e => e.key === topLevelSeasonKey).map(e => e.name)[0]
        }

        const renderNavLink = (path, langLabel) => {
            return <NavLink exact to={path} activeClassName="selected">{ lang.d(langLabel) }</NavLink>
        }

        return <div className="EventDetail">
            <div className="top-panels">
                <div className="controls-panel">
                    { topLevelSeasonKey && <NavLink className="over-title" to={`/charts/${this.props.match.params.chartKey}/events/${topLevelSeasonKey}`}>
                        <div className="icon icon-season"></div>
                        <div className="label">{ topLevelSeasonName ? topLevelSeasonName : topLevelSeasonKey }</div>
                    </NavLink> }
                    <div className={`title ${!name && 'placeholder'}`}>
                        <span>{ name || lang.d('no_name') }</span>
                        { (!isInThePast) &&  <button onClick={e => this.showUpdateNameOptions(e)}>
                            <span className={`icon icon-${name ? 'settings' : 'plus-bold'}`} />
                        </button> }
                    </div>
                    <div className="title small fixed-width">
                        <span>{ key }</span>
                        { canEditKey && <button onClick={() => this.props.updateEventKey(this.state.event.chartKey, this.state.event.key, this.props.refresh)}>
                            <span className="icon icon-edit" />
                        </button> }
                    </div>
                    <div className="event-actions">
                        { isInThePast && <div className="action-note">
                            <div className="icon icon-event-done"></div>
                            <div className="label">This event is in the past</div>
                        </div> }
                        <button onClick={() => this.requestEventDelete()}>
                            <span className="icon icon-delete" />
                            <span className="caption">{ lang.d(this.isSeason() ? 'delete_season' : 'delete_event') }</span>
                        </button>
                        { (isEventInSeason && !isInThePast) && <button onClick={() => this.requestMarkAsInThePast()}>
                            <span className="icon icon-event-done" />
                            <span className="caption">{ lang.d('mark_as_in_the_past') }</span>
                        </button> }
                        { isTopLevelSeason && <button onClick={() => this.createSeasonEvent()} className={creatingSeasonalEvent ? 'loading' : undefined}>
                            <span className="icon icon-plus-bold" />
                            <span className="caption">{ lang.d('create_event_in_season') }</span>
                        </button> }
                        { isTopLevelSeason && <button onClick={() => this.createPartialSeason()} className={creatingPartialSeason ? 'loading' : undefined}>
                            <span className="icon icon-partial-season" />
                            <span className="caption">{ lang.d('create_partial_season') }</span>
                        </button> }
                        { isPartialSeason && <button onClick={() => this.managePartialSeasonEvents()} className={managingPartialSeasonEvents ? 'loading' : undefined}>
                            <span className="icon icon-settings" />
                            <span className="caption">{ lang.d('manage_events') }</span>
                        </button> }
                    </div>
                </div>
                <div className="info-panel">
                    { this.renderOccupancyProgressBar() }
                    { createdOn &&
                        <div className="definition">
                            <span className="label">{ lang.d('created_on') }</span>
                            <span className="value">{ createdOn.toLocaleString() }</span>
                        </div>
                    }
                </div>
            </div>
            <PageSwitcher>
                { !isInThePast && [
                    renderNavLink(basePath, 'summary'),
                    renderNavLink(`${basePath}/statuses`, 'statuses'),
                    renderNavLink(`${basePath}/forsale`, 'for_sale'),
                    renderNavLink(`${basePath}/tables`, 'tables'),
                    renderNavLink(`${basePath}/channels`, 'channels'),
                    renderNavLink(`${basePath}/categories`, 'categories')
                ] }
                { renderNavLink(`${basePath}/statuschanges`, 'status_changes') }
            </PageSwitcher>
            <div className="subpage" style={{ minHeight: this.state.subpageHeight ? `${this.state.subpageHeight}px` : undefined }} ref={this.subpageRef}>
                <Switch>
                    { !isInThePast && <PrivateRoute exact path={baseRoute} component={Summary} /> }
                    { !isInThePast && <PrivateRoute exact path={`${baseRoute}/statuses`} render={props => <ManageStatuses {...props} onEventManagerSubmit={() => this.refreshEventSummary()} />} /> }
                    { !isInThePast && <PrivateRoute exact path={`${baseRoute}/forsale`} render={props => <ManageForSale {...props} onEventManagerSubmit={() => this.refreshEventSummary()} />} /> }
                    { !isInThePast && <PrivateRoute exact path={`${baseRoute}/tables`} component={ManageTables} /> }
                    { !isInThePast && <PrivateRoute exact path={`${baseRoute}/channels`} component={ManageChannels} /> }
                    { !isInThePast && <PrivateRoute exact path={`${baseRoute}/categories`} component={ManageCategories} /> }
                    <PrivateRoute exact path={`${baseRoute}/statuschanges`} component={StatusChanges} />
                    <PrivateRoute><Redirect to={isInThePast ? `${basePath}/statuschanges` : basePath} /></PrivateRoute>
                </Switch>
            </div>
        </div>
    }
}

EventDetail.propTypes = {
    removeEvent: PropTypes.func.isRequired,
    updateEventKey: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    match: PropTypes.object,
    refresh: PropTypes.func,
    onEventChanges: PropTypes.func,
    chartEvents: PropTypes.arrayOf(PropTypes.object),
    playNewestAddedEventKey: PropTypes.func.isRequired,
    context: PropTypes.object,
    updateEventName: PropTypes.func.isRequired,
    clearSearch: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    updateEventKey: (chartKey, currentKey, refreshCallback) => dispatch(updateEventKey(chartKey, currentKey, refreshCallback)),
    updateEventName: (eventKey, currentName, isSeason, onSubmitSuccessCallback) => dispatch(updateEventName(eventKey, currentName, isSeason, onSubmitSuccessCallback)),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload)),
    playNewestAddedEventKey: key => playNewestAddedEventKey(dispatch, key)
})

export default connect(null, mapDispatchToProps)(EventDetail)
